import Footer from "../footer";

const Guidelines = (): JSX.Element => {
  return (
    <>
      <div id="container">
        <h1>Community Guidelines</h1>
        <div className="section">
          <p>We created these Community Guidelines to support our mission by encouraging the broadest range of self-expression while making sure our users can use our services safely every day.<br /><br />
            These Guidelines apply to all content on Hoop.</p>
        </div>
        <br />
        <h1>Sexually Explicit Content</h1>
        <div className="section">
          <p>•	We prohibit accounts that promote or distribute pornographic content.<br /><br />
            •	Never post, save, or send nude or sexual content involving anyone under the age of 18 — even of yourself. Never ask a minor to send explicit imagery or chats. We report child sexual exploitation to authorities.</p>
        </div>
        <br />
        <h1>Harassment &amp; Bullying</h1>
        <div className="section">
          <p>•	We have zero tolerance for bullying or harassment of any kind.<br /><br />
            •	Don’t send a photo with the intention of making someone feel bad. If someone blocks you, don’t try to contact them from another account.<br /><br />
            •	If someone is in your photo and asks you to remove it, please do!</p>
        </div>
        <br />
        <h1>Threats, Violence &amp; Harm</h1>
        <div className="section">
          <p>•	Never threaten to harm a person, a group of people, or someone’s property. Encouraging violence is prohibited on Hoop.<br /><br />
            •	Don't post photos of gratuitous violence, including animal abuse.<br /><br />
            •	We don’t allow the glorification of self-harm, including the promotion of self-injury or eating disorders.</p>
        </div>
        <br />
        <h1>Impersonation &amp; Spam</h1>
        <div className="section">
          <p>•	Don’t pretend to be someone you’re not — this includes your friends, celebrities, brands, or other organizations — or attempt to deceive people about who you are. <br /><br />
            •	We prohibit spam and deceptive practices.</p>
        </div>
        <br />
        <h1>Hate Speech &amp; False Information</h1>
        <div className="section">
          <p>•	Don't post any content that demeans, defames, or promotes discrimination or violence on the basis of race, ethnicity, national origin, religion, sexual orientation, gender identity, disability, or veteran status. <br /><br />
            •	We prohibit malicious deception and deliberately spreading false information that causes harm, such as denying the existence of tragic events. </p>
        </div>
        <br />
        <h1>Illegal Content</h1>
        <div className="section">
          <p>•	Don’t use Hoop for any illegal activities — including to buy or sell illegal drugs, contraband, counterfeit goods, or illegal weapons.</p>
        </div>
        <br />
        <h1>Terrorism</h1>
        <div className="section">
          <p>•	Terrorist organizations are prohibited from using our platform and we have no tolerance for content that advocates or advances terrorism.</p>
        </div>
        <br /><br />
        <p>Please remember that you can always report inappropriate content or activity using our in-app reporting feature. We review these reports to determine whether there is a violation of these Guidelines and any action needs to be taken.<br /><br />
          If you violate these Community Guidelines, we may remove the offending content, terminate your account, and/or notify law enforcement. If your account is terminated for violating our Terms of Service or these Guidelines, you may not use Hoop again. Please take these Guidelines seriously and honor them in the spirit in which they are intended. We’ll do our best to enforce them consistently and fairly, and ultimately we’ll try to do what we think best reflects these values in each situation in our sole discretion.</p>
        <br />
      </div>
      <Footer></Footer>

    </>
  );
}

export default Guidelines;