import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import Wallet from './pages/wallet';
import HttpPage from './pages/Request';
import Terms from './pages/terms';
import Guidelines from './pages/guidelines';
import Safety from './pages/safety';
import ParentsGuide from './pages/parents_guide';
import LegalNotice from './pages/legal-notice';
import PrivacyPolicy from './pages/privacy_policy';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/guidelines" element={<Guidelines />} />
          <Route path="/safety" element={<Safety />} />
          <Route path="/parents_guide" element={<ParentsGuide />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/http" element={<HttpPage />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
