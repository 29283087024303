/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import Footer from "../footer";
import "./index.scss";

const Home = (): JSX.Element => {
  return (
    <>
      <div id="container">
        <div id="main">
          {/* <div>
            <img id="logo_hoop" src={require('.. /../assets/img/logo.png').default} alt="witcoin_logo" />
          </div> */}
          <div id="content">
            <div className="left">
              <h1 id="headline" >
                <img src={require('../../assets/img/title.png').default} alt="" />
              </h1>
              <p className="des">
                <img src={require('../../assets/img/des.png').default} alt="" />
              </p>
              <p
                style={{
                  fontSize: '2.8em',
                  color: '#fff',
                  fontWeight: '800'
                }}
              >
                西安逆熵科技有限公司
              </p>
              <div id="store-button">
                {/* <a href="https://itunes.apple.com/fr/app/dazz/id1442571346?mt=8" target="_blank">
                <img className="store_image" src={require('../../assets/img/app_store_en.png').default} alt="app_store" />
              </a> */}
                <a href="#" target="_blank">
                  <img className="store_image" src={require('../../assets/img/play_store_en.png').default} alt="play_store" />
                </a>
              </div>
            </div>
            {/* <div className="right">
              <img className="" src={require('../../assets/img/logo.png').default} alt="app_store" width={'100%'} />
            </div> */}

          </div>
        </div>
      </div>
      <div className="home_footer">
        <Footer></Footer>
      </div>

    </>
  );
}

export default Home;
