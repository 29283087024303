import Footer from "../footer";

const Safety = (): JSX.Element => {
  return (
    <>
      <div id="header">
        <a className="active" href="/safety">Safety on Hoop</a>
        <a href="/parents_guide">Parent's Guide</a>
      </div>
      <div id="container">
        <h1>Our approach to safety</h1>
        <div className="section">
          <p>Hoop is an app to make new friends online all around the world. There is <span>no direct interaction</span> on Hoop. Users can simply send friend requests.<br /><br />
            If you are a parent and your child wants to use Hoop, please read our <a id="regles" href="/parents_guide">Parent's Guide</a>.<br /><br />
            Millions of people from all over the world use Hoop and, like in the real life, users are exposed to risks online. You will find below our approach to safety.</p>
        </div>
        <br />
        <h1>Safety when signing up</h1>
        <div className="section">
          <p>• Users on Hoop must be aged 13 and above. They must provide their real date of birth. If we identify an underage profile or if a profile is reported as underaged by the community, we terminate the related account.<br /><br />
            • 13 to 17 years old are not allowed to create an account or to use Hoop without the permission of their legal representative. If legal representatives need to know more about Hoop, they can read our <a id="regles" href="/parents_guide">Parent's Guide</a> or contact us at <a id="email" href="mailto:hello@hoop.photo  ">hello@hoop.photo</a>.<br /><br />
            • When creating their account, users on Hoop accept the <a target="_blank" id="regles" href="/guidelines"> Community Guidelines</a> that make clear that we do not want Hoop put to bad uses.</p>
        </div>
        <br />
        <h1>Safety when setting up a profile</h1>
        <div className="section">
          <p>• To use Hoop, users have to give their date of birth so we can show them profiles from their age group. Once the date of birth is set, users can’t change it. If we identify or if the community reports a user who lies about their date of birth, we terminate their account.<br /><br />
            • Users on Hoop can choose to hide their age. They can manage this setting from the app, at any time.<br /><br />
            • The socials links of users on Hoop are private. Users have to be friends on Hoop to access each other socials links.<br /><br />
            • We separate the 13 to 17 years old from the 18 years old and older, meaning that adults can’t see teenagers’ profiles and vice versa. The age filter is enabled by default and can’t be disabled or changed, meaning that users will see profiles from their age group.<br /><br />
            • Since Hoop is an app to make new friends online all over the world, there is <span>no location feature</span> on Hoop and we do not request access to the location, meaning that they can't choose to see profiles nearby. They simply have to select their own country in a list.</p>
        </div>
        <br />
        <h1>Safety when posting content</h1>
        <div className="section">
          <p>What can users post on Hoop?<br /><br />
            - Up to 3 photos<br />
            • We use technical tools to filter these content:<br />
            - we perform image analysis to make sure that users on iOS post at least one photo that shows their face.<br />
            - we perform image and text analysis to prevent users from posting inappropriate content such as explicit language, nudity or sexual content.<br /><br />
            • If inappropriate content is not flagged by our technical tools:<br />
            - human moderators can remove it and terminate the related account,<br />
            - the community can report it. Reported content is reviewed by our human moderators within maximum 48 hours. They can remove this content and terminate the related account.<br /><br />
            • If a user on Hoop breaks any rules from our <a target="_blank" id="regles" href="/guidelines"> Community Guidelines</a>, we terminate their account.<br /><br />
            • We remind users of our Community Guidelines when they post content for the first time.<br /><br />
            • There is <span>no chat feature</span> in Hoop, meaning that users can't send direct message.<br /></p>
        </div>
        <br />
        <h1>Safety Tools for Hoop users</h1>
        <div className="section">
          <p>We provide our users with reporting and blocking features:<br /><br />
            • Users on Hoop can easily report an inappropriate content or activity by tapping on the flag button on the profile photos or on the option button on the profile screen. Reported profiles are reviewed by human moderators within maximum 48 hours.<br /><br />
            • Users on Hoop can easily block a user by tapping on the flag button on the profile photos or on the option button on the profile screen. If a user blocks another user, they will no longer see each other on the app. Users on Hoop can manage their blocked users from their profile’s settings at any time.<br /><br />
            • If a user needs help about any personal issues, they can easily contact us at:<br /><br />
            - <a id="email" href="mailto:support@hoop.photo">support@hoop.photo</a> directly from their profile’s settings<br />
            - <a id="email" href="mailto:hello@hoop.photo  ">hello@hoop.photo  </a></p>
        </div>
        <br />

      </div>
      <Footer></Footer>

    </>
  );
}

export default Safety;