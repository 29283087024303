import Footer from "../footer";

const LegalNotice = (): JSX.Element => {
  return (
    <>
      <div id="container">
        <h1>Editor</h1>
        <div className="section">
          <p>The website www.hoop.photo is published by the company Dazz SAS, registered in the register of trade and companies of Besançon under the number RCS 823 043 591, capital of 79 950 €. The head office is at 11 rue du chalet, 25620 TARCENAY-FOUCHERANS, France.<br />
            VAT number Intracommunity: FR 53 823043591<br />
            Director of Publication: Lucas Gervais<br />
            Contact : <a id="email" href="mailto:hello@hoop.photo  ">hello@hoop.photo  </a></p>
        </div>
        <br /><br />
        <h1>Host</h1>
        <div className="section">
          <p>OVH<br />
            2 rue Kellermann – 59100 ROUBAIX</p>
        </div>
        <br /><br />
        <h1>Intellectual property</h1>
        <div className="section">
          <p>Dazz SAS owns the intellectual property rights or holds the rights to use all the elements available on the site, including text, images, graphics, logos, icons.<br />
            Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, whatever the means or process used, is prohibited without prior written permission of Dazz SAS.<br />
            Any unauthorized use of the site or any of the elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions of Articles L.335-2 and following of the Code of Intellectual Property.</p>
        </div>
        <br />
      </div>
      <Footer></Footer>

    </>
  );
}

export default LegalNotice;