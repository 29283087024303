import Footer from "../footer";

const ParentsGuide = (): JSX.Element => {
  return (
    <>
      <div id="header">
        <a href="/safety">Sécurité sur Hoop</a>
        <a className="active" href="/parents_guide">Guide pour les parents</a>
      </div>
      <div id="container">
        <h1>Parent's Guide to Hoop</h1>
        <div className="section">
          <p>Hoop is a popular app to make new friends on online all around the world.<br /><br />
            Our goal is to help social apps users to make new friends in an easy, fun and safe way.<br /><br />
            We have built this Guide as an FAQ to help parents to understand what Hoop is, how teenagers use it and how to help your child stay safe on Hoop.
          </p>
        </div>
        <br />
        <h1>What is Hoop?</h1>
        <div className="section">
          <p>Hoop is a social app free to download from the App Store and Play Store. Millions of young people from all over the world use Hoop to make new friends online.</p>
        </div>
        <br />
        <h1>What is the minimum age on Hoop?</h1>
        <div className="section">
          <p>The minimum age is 13. 13 to 17 years old are not allowed to create an account or to use Hoop without the permission of their legal representative.<br /><br />
            When setting up a profile, we ask for a date of birth that can’t be changed to separate the 13 to 17 years old from the 18 years old and above, meaning that adults can’t see teenagers’ profiles and vice versa.
          </p>
        </div>
        <br />
        <h1>How does Hoop work?</h1>
        <div className="section">
          <p>Users on Hoop can send the profiles they like a friend request.<br /><br />
            When receiving a friend request, users can:<br />
            • accept the request. The two users are now friends and can access each other's socials links.<br />
            • decline. Nothing happens.<br /><br />
            Users on Hoop maintain control over the users they want to be friend with.<br /><br />.
            <br /></p>
        </div>
        <br />
        <h1>What about content posted on Hoop?</h1>
        <div className="section">
          <p>Like with all digital media, there are risks of being exposed to inappropriate content that breaks our <a target="_blank" id="regles" href="/guidelines">Community Guidelines</a>.<br /><br />
            Users on Hoop can post up to 3 photos. This content is filtered by our technical tools, our human moderators and the community. You can find more information about our approach to safety on our <a id="regles" href="/safety"> Safety</a> page.<br /><br />
            If a user breaks any rules from our <a target="_blank" id="regles" href="/guidelines"> Community Guidelines</a>, we delete their content and terminate their account.<br /><br />
            Please note that there is <span>no location feature</span> meaning that users on Hoop can’t choose to see profiles nearby and there is <span>no chat feature</span> meaning that users on Hoop can’t send direct message.
          </p>
        </div>
        <br />
        <h1>Why do teenagers use Hoop?</h1>
        <div className="section">
          <p>Teenagers use Hoop to make new friends all around the world, share their passions with teenagers from different countries and much more.<br /><br />
            We encourage you to talk to your child about why they use Hoop and other social apps. It will help you better understand your child’s online activity and they are more likely to come to you if they need help.
          </p>
        </div>
        <br />
        <h1>How do we help our users to stay safe?</h1>
        <div className="section">
          <p>Like in the real life, users are exposed to risks online. We have developed safety tools and features to help our users to stay safe on Hoop and have a friendly experience. You can find more information about our approach to safety on our <a id="regles" href="/safety"> Safety</a> page.<br /><br />
            Users on Hoop can easily report an inappropriate content or activity or block an abusive user. If anything goes wrong, users can contact us at:<br /><br />
            - <a id="email" href="mailto:support@hoop.photo">support@hoop.photo</a> directly from their profile’s settings<br />
            - <a id="email" href="mailto:hello@hoop.photo  ">hello@hoop.photo  </a><br /><br />
            You should know that there is <span>no direct interaction</span> on Hoop. However, Hoop allows users to connect on other social apps.
          </p>
        </div>
        <br />
        <h1>What can parents do to help their child stay safe on Hoop?</h1>
        <div className="section">
          <p>• The most important thing is to talk with your child about being thoughtful while using social apps.<br /><br />
            • Remind them that they can come to you or speak to a trusted adult when they need help.<br /><br />
            • Encourage them to respect themselves and other users.
          </p>
        </div>
        <br /><br />
        <p>If you have any question, feel free to contact us at <a id="email" href="mailto:hello@hoop.photo  ">hello@hoop.photo</a>. We will be happy to answer 🙂</p>
        <br />
      </div>
      <Footer></Footer>

    </>
  );
}

export default ParentsGuide;